import React, { FC, useContext } from 'react'
import { DefaultTheme, ThemeContext } from 'styled-components'

interface LogoProps {
  color: keyof DefaultTheme['colors']
}

export const TwitterLogo: FC<LogoProps> = ({ color }) => {
  const { colors } = useContext(ThemeContext)

  return (
    <svg
      width="33"
      height="28"
      viewBox="0 0 33 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.8256 4.58936C30.2076 3.74083 31.2417 2.40473 31.7347 0.830523C30.4361 1.62188 29.0153 2.17933 27.5337 2.47877C25.4796 0.247108 22.225 -0.295978 19.5886 1.15298C16.9521 2.60193 15.5866 5.68421 16.2549 8.67752C10.9355 8.40325 5.97961 5.82264 2.62037 1.57786C0.867247 4.68344 1.76313 8.65343 4.6677 10.6504C3.61738 10.6157 2.59035 10.3236 1.67224 9.79852C1.67224 9.82702 1.67224 9.85552 1.67224 9.88402C1.67285 13.119 3.89282 15.9056 6.9802 16.5467C6.00597 16.8189 4.98406 16.859 3.99245 16.6639C4.86072 19.4304 7.34334 21.3256 10.173 21.3822C7.8294 23.2713 4.93514 24.2958 1.95591 24.2908C1.42784 24.2916 0.900193 24.2604 0.375702 24.1974C3.40105 26.194 6.92219 27.2538 10.5183 27.25C15.5214 27.2853 20.3295 25.2596 23.8672 21.6261C27.4049 17.9925 29.3768 13.0542 29.3421 7.91594C29.3421 7.62144 29.3354 7.32852 29.322 7.03719C30.6177 6.07548 31.736 4.88409 32.6243 3.51902C31.4172 4.06854 30.1368 4.42932 28.8256 4.58936Z"
        fill={colors[color]}
      />
    </svg>
  )
}
